import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PipesModule} from './pipes/pipes.module';
import {PartialsModule} from './partials/partials.module';
import {RouterModule} from '@angular/router';
import {DialogsModule} from './dialogs/dialogs.module';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {PagesModule} from './pages/pages.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {CdkTableModule} from '@angular/cdk/table';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {RootsModule} from './roots/roots.module';
import {HttpClientModule} from '@angular/common/http';
import {LightboxModule} from 'ngx-lightbox';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {BriefdFormModule} from './briefd-form/briefd-form.module';
import {SignaturePadModule} from 'angular2-signaturepad';
import {NgChartsModule} from 'ng2-charts';
import {DirectivesModule} from './directives/directives.module';
import {AdvertisingLogoComponent} from '@app/partials/advertising-logo/advertising-logo.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,

    MatDialogModule,
    CdkTableModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDatepickerModule,
    DragDropModule,
    MatTooltipModule,
    NgxDropzoneModule,
    MatSnackBarModule,
    ClipboardModule,
    LightboxModule,

    PipesModule,
    PartialsModule,
    DialogsModule,
    PagesModule,
    RootsModule,
    BriefdFormModule,
    SignaturePadModule,

    NgChartsModule,
    DirectivesModule
  ],
  declarations: [
    AdvertisingLogoComponent
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    LightboxModule,

    MatDialogModule,
    CdkTableModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatDatepickerModule,
    DragDropModule,
    MatTooltipModule,
    NgxDropzoneModule,
    MatSnackBarModule,
    ClipboardModule,

    PipesModule,
    PartialsModule,
    DialogsModule,
    PagesModule,
    RootsModule,

    BriefdFormModule,
    SignaturePadModule,
    AdvertisingLogoComponent,
  ]
})
export class SharedModule {
}
