import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {ConfigAPIService} from "@app/services/api/config-api.service";
import {IntegrationTypeEnum} from "@app/enums/IntegrationTypeEnum";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Directive({
  selector: '[appIfIntegrationOn]'
})
export class IfIntegrationOnDirective implements OnInit, OnDestroy {
  @Input('appIfIntegrationOn') public integration: IntegrationTypeEnum;

  private destroy$ = new Subject<void>();

  constructor(private viewContainerRef: ViewContainerRef,
              private templateRef: TemplateRef<any>,
              private configApiService: ConfigAPIService) {
  }

  ngOnInit(): void {
    this.configApiService.getAccountConfig().pipe(takeUntil(this.destroy$)).subscribe((config) => {
      const integrationActive = config.accountIntegrations.find((item) => item.type.toLowerCase() === this.integration);

      this.viewContainerRef.clear();
      if (integrationActive?.isActive) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
