import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {Field} from '@app/interfaces';
import {merge, Observable, Subject} from "rxjs";
import {map, startWith, takeUntil} from "rxjs/operators";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {TestPlatesDialogComponent} from "@app/dialogs/test-plates-dialog/test-plates-dialog.component";
import {AutocompleteValuesApiService} from "@app/services/api/autocomplete-values-api.service";
import {AutocompleteValues} from "@app/interfaces/entities/autocomplete-values";
import {firstKey} from "@app/util/object.helper";

@Component({
  selector: 'app-form-input-test-plates-autocomplete',
  templateUrl: './form-input-test-plates-autocomplete.component.html',
  styleUrls: ['./form-input-test-plates-autocomplete.component.scss']
})
export class FormInputTestPlatesAutocompleteComponent implements OnInit, OnDestroy {
  @Input('optionsControl') optionsControl: FormControl = new FormControl();
  @Input('control') control: AbstractControl;
  @Input() controls: Array<AbstractControl>;
  @Input('formGroup') formGroup: FormGroup;
  @Input('field') field: Field<number>;

  options = [];
  filteredOptions$: Observable<AutocompleteValues[]>;
  public administrativeNumberControl = new FormControl('');

  private destroy$ = new Subject<void>();
  private updateFilteredOptions$ = new Subject<string>();

  constructor(private cdr: ChangeDetectorRef,
              private matDialog: MatDialog,
              private autocompleteValuesApiService: AutocompleteValuesApiService,
  ) {

  }

  ngOnInit() {
    const administrativeNumberUuid = this.field.connectedField.fieldUuid ?? this.field.connectedField.uuid;
    this.administrativeNumberControl = this.controls[administrativeNumberUuid];

    this.autocompleteValuesApiService.getOptions(this.field.config.listKey).subscribe((result) => {
      this.options = result.data;
      this.options = this.transformOptions(this.options);

      this.optionsControl.setValue(this.field.value, {emitEvent: false});
      this.control.setValue(this.field.value);

      this.filteredOptions$ = merge(
        this.optionsControl.valueChanges,
        this.updateFilteredOptions$
      ).pipe(
        takeUntil(this.destroy$),
        startWith(''),
        map(value => typeof value === 'string' ? value : value.number),
        map(value => value ? this.filterOptions(value) : this.options.slice(0, 10))
      );
    }, (error) => {
      console.log(error)
    });

    this.optionsControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      const selectedOption = this.options.find(option => option.value === value);
      if (selectedOption) {
        this.optionsControl.setValue(selectedOption.value, {emitEvent: false});
        this.control.setValue(selectedOption.value);

        this.administrativeNumberControl.setValue(selectedOption.license);
      } else {
        this.optionsControl.setValue(value, {emitEvent: false});
        this.control.setValue(value);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  private filterOptions(value: string): AutocompleteValues[] {
    return this.options.filter(options => options.value.includes(value)).slice(0, 10);
  }

  transformOptions(options: any[]): any[] {
    return options.map(option => {
      if (typeof(option.value) === 'object') {
        return {
          list_key: option.list_key,
          value: option.value.value || '',
          license: option.value.license || '',
        };
      }

      return option;
    });
  }

  editTestPlates() {
    const dialogRef = TestPlatesDialogComponent.open(this.matDialog, this.field.config);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.options = result;
      }
    });
  }
}
