import {AfterViewInit, ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import {SignaturePad} from 'angular2-signaturepad';
import {SignatureService} from '@app/services/signature.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SignatureComponentDialogComponent} from '@app/dialogs/signature-component-dialog/signature-component-dialog.component';

@Component({
  selector: 'app-form-input-signature',
  templateUrl: './form-input-signature.component.html',
  styleUrls: ['./form-input-signature.component.scss']
})
export class FormInputSignatureComponent implements AfterViewInit {

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  @Input() control: AbstractControl;

  @Input() field: Field<string>;

  // TODO: IMPLEMENT THIS ON THE API SIDE
  public signaturePadOptions: any = {
    minWidth: 1,
    canvasWidth: 300,
    canvasHeight: 188
  };

  constructor(private signatureService: SignatureService, private matDialog: MatDialog, private ref: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    if (this.field.value) {
      this.signaturePad.fromDataURL(this.field.value);
    } else {
      this.signaturePad.clear();
    }
  }

  clear() {
    this.signaturePad.clear();
    this.signaturePad.on();
    this.control.setValue(null);
  }

  submit() {
    this.signaturePad.off();
    this.control.setValue(this.signaturePad.toDataURL());
  }

  refresh() {
    this.signatureService.refresh().subscribe((result) => {
      if (result.data.signature) {
        this.signaturePad.fromDataURL(result.data.signature);
        this.control.setValue(result.data.signature);
      }
    });
  }

  openSignatureComponent() {
    const dialogRef = SignatureComponentDialogComponent.open(this.matDialog, this.field.fieldUuid, this.field.config.label);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.signaturePad.fromDataURL(result);
        this.signaturePad.off();
        this.control.setValue(this.signaturePad.toDataURL());
        this.ref.markForCheck();
      }
    });
  }
}
