import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {Field} from "../../../../interfaces";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
    selector: 'app-form-input-boolean',
    templateUrl: './form-input-boolean.component.html',
    styleUrls: ['./form-input-boolean.component.scss']
})
export class FormInputBooleanComponent implements OnInit, OnDestroy {

    @Input() control: AbstractControl;

    @Input() hideLabel = false;

    @Input() field: Field<string>;

    private destroy$ = new Subject<void>();

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.control.statusChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.cdr.detectChanges();
            });

        if (!this.control.value && this.field.config.default) {
            this.control.setValue(this.field.config.default);
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
    }
}
