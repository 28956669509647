import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {PipesModule} from '../pipes/pipes.module';
import {PartialsModule} from '../partials/partials.module';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
  AddPhotoDialogComponent,
  AddUserDialogComponent,
  ChangeEntityCategoryDialogComponent,
  ChangeEntityGroupsDialogComponent,
  ChangeStatusDialogComponent,
  ConfirmDialogComponent,
  EditFieldDialogComponent,
  ExportDocumentDialogComponent,
  FormAlarmDialogComponent,
  FormPrefillDocumentTemplateDialogComponent,
  MaxUserDialogComponent,
  RepeaterItemFormDialogComponent,
  ShareAdDialogComponent,
} from './index';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {FieldItemFormDialogComponent} from './field-item-form-dialog/field-item-form-dialog.component';
import {AddVehicleToCompareComponent} from './add-vehicle-to-compare/add-vehicle-to-compare.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {CdkTableModule} from '@angular/cdk/table';
import {AddVehicleToProcessComponent} from './add-vehicle-to-process/add-vehicle-to-process.component';
import {OpenProcessFormComponent} from './open-process-form/open-process-form.component';
import {SelectClientDialogComponent} from './select-client-dialog/select-client-dialog.component';
import {RouterModule} from '@angular/router';
import {SelectVehicleDialogComponent} from './select-vehicle-dialog/select-vehicle-dialog.component';
import {SelectClientTypeDialogComponent} from './select-client-type-dialog/select-client-type-dialog.component';
import {ChangeClientEntityGroupDialogComponent} from './change-client-entity-group-dialog/change-client-entity-group-dialog.component';
import {EntityRelationshipDialogComponent} from './entity-relationship-dialog/entity-relationship-dialog.component';
import {QuickCreateEntityDialogComponent} from './quick-create-entity-dialog/quick-create-entity-dialog.component';
import {RefreshBrowserDialogComponent} from './refresh-browser-dialog/refresh-browser-dialog.component';
import {GdprDialogComponent} from './gdpr-dialog/gdpr-dialog.component';
import {DmvCalculatorDialogComponent} from './dmv-calculator-dialog/dmv-calculator-dialog.component';
import {SelectIbanDialogComponent} from './select-iban-dialog/select-iban-dialog.component';
import {SelectInvoiceSearchItemDialogComponent} from './select-invoice-search-item-dialog/select-invoice-search-item-dialog.component';
import {SelectInvoiceItemDialogComponent} from './select-invoice-item-dialog/select-invoice-item-dialog.component';
import {SelectInvoiceTypeVehicleDialogComponent} from './select-invoice-type-vehicle-dialog/select-invoice-type-vehicle-dialog.component';
import {PaymentFormDialogComponent} from './payment-form-dialog/payment-form-dialog.component';
import {AddReferenceAnyDocumentDialogComponent} from './add-reference-invoice-document-dialog/add-reference-any-document-dialog.component';
import {SelectInvoiceTypeDialogComponent} from './select-invoice-type-dialog/select-invoice-type-dialog.component';
import {SignatureComponentDialogComponent} from './signature-component-dialog/signature-component-dialog.component';
import {QRCodeModule} from 'angularx-qrcode';
import {NlbLeasingInfoCalculatorComponent} from './nlb-integration/nlb-leasing-info-calculator/nlb-leasing-info-calculator.component';
import {CompanyTypeClientDialogComponent} from './company-type-client-dialog/company-type-client-dialog.component';
import {SwitchToFullModeDialogComponent} from './switch-to-full-mode-dialog/switch-to-full-mode-dialog.component';
import {UpsSwitchToFullModeDialogComponent} from './ups-switch-to-full-mode-dialog/ups-switch-to-full-mode-dialog.component';
import {OnboardingFormDialogComponent} from './onboarding-form-dialog/onboarding-form-dialog.component';
import {OnboardingSuccessDialogComponent} from './onboarding-success-dialog/onboarding-success-dialog.component';
import {SwitchToFullModeSuccessfullDialogComponent} from './switch-to-full-mode-successfull-dialog/switch-to-full-mode-successfull-dialog.component';
import {CompletedWelcomeOnboardingDialogComponent} from './completed-welcome-onboarding-dialog/completed-welcome-onboarding-dialog.component';
import {OnboardingLastQuestionDialogComponent} from './onboarding-last-question-dialog/onboarding-last-question-dialog.component';
import {FirstInvoiceCreatedDialogComponent} from './first-invoice-created-dialog/first-invoice-created-dialog.component';
import {ReadMoreMotiveServiceDialogComponent} from './read-more-motive-service-dialog/read-more-motive-service-dialog.component';
import {HowToIssueMotiveServiceDialogComponent} from './how-to-issue-motive-service-dialog/how-to-issue-motive-service-dialog.component';
import {ReadMoreAboutWarrantyMotiveServiceDialogComponent} from './read-more-about-warranty-motive-service-dialog/read-more-about-warranty-motive-service-dialog.component';
import {SignupFormDialogComponent} from './signup-form-dialog/signup-form-dialog.component';
import {MotiveServiceChoosePackageDialogComponent} from './motive-service-choose-package-dialog/motive-service-choose-package-dialog.component';
import {FiscalizationBusinessPremiseFormDialogComponent} from './fiscalization-business-premise-form-dialog/fiscalization-business-premise-form-dialog.component';
import {FiscalizationElectronicDeviceFormDialogComponent} from './fiscalization-electronic-device-form-dialog/fiscalization-electronic-device-form-dialog.component';
import {BriefdFormModule} from '../briefd-form/briefd-form.module';
import {EditEntityGroupDialogComponent} from './edit-entity-group-dialog/edit-entity-group-dialog.component';
import {SuccessExportedSpaceInvoicesDocumentDialogComponent} from './success-exported-space-invoices-document-dialog/success-exported-space-invoices-document-dialog.component';
import {NlbLeaseAndGoPromoDialogComponent} from './nlb-lease-and-go-promo-dialog/nlb-lease-and-go-promo-dialog.component';
import {AdministrationInvalidNumberErrorDialogComponent} from './administration-invalid-number-error-dialog/administration-invalid-number-error-dialog.component';
import {RequireHelpDialogComponent} from './require-help-dialog/require-help-dialog.component';
import {MotivePackagesDialogComponent} from './motive-packages-dialog/motive-packages-dialog.component';
import {TransformEstimateToInvoiceWarningDialogComponent} from './transform-estimate-to-invoice-warning-dialog/transform-estimate-to-invoice-warning-dialog.component';
import {MotiveServiceBuyPackagesDialogComponent} from './motive-service-buy-packages-dialog/motive-service-buy-packages-dialog.component';
import {MotiveServiceBuyPackagesSuccessDialogComponent} from './motive-service-buy-packages-success-dialog/motive-service-buy-packages-success-dialog.component';
import {SuccessExportedBussinesOverviewComponent} from './success-exported-bussines-overview/success-exported-bussines-overview.component';
import {ChangeVehicleStatusOverviewTableDialogComponent} from './change-vehicle-status-overview-table-dialog/change-vehicle-status-overview-table-dialog.component';
import {NlbTypeFormDialogComponent} from './nlb-type-form-dialog/nlb-type-form-dialog.component';
import {NlbOpenDraftsDialogComponent} from './nlb-open-drafts-dialog/nlb-open-drafts-dialog.component';
import {CreateCashReceiptDialogComponent} from './create-cash-receipt/create-cash-receipt-dialog.component';
import {FursConfirmDialogComponent} from './furs-confirm-dialog/furs-confirm-dialog.component';
import {FursAutomaticCreditNoteDialogComponent} from './furs-automatic-credit-note-dialog/furs-automatic-credit-note-dialog.component';
import {SelectFileUploadFromDialogComponent} from './select-file-upload-from-dialog/select-file-upload-from-dialog.component';
import {SelectFilesDialogComponent} from './select-files-dialog/select-files-dialog.component';
import {RestoreVehicleDialogComponent} from './restore-vehicle-dialog/restore-vehicle-dialog.component';
import {AlreadySoldMoveToArchiveDialogComponent} from './already-sold-move-to-archive-dialog/already-sold-move-to-archive-dialog.component';
import {AgreeWithAmpTermsDialogComponent} from './agree-with-amp-terms-dialog/agree-with-amp-terms-dialog.component';
import {UserExistsDialogComponent} from './user-exists-dialog/user-exists-dialog.component';
import {FieldCurrencyFormDialogComponent} from './field-currency-form-dialog/field-currency-form-dialog.component';
import {CreateVehicleDialogComponent} from './create-vehicle-dialog/create-vehicle-dialog.component';
import {EmailDialogComponent} from './email-dialog/email-dialog.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {DirectivesModule} from '../directives/directives.module';
import {FiscalizationBusinessPremiseFinaDialogContentComponent} from '@app/dialogs/fiscalization-business-premise-fina-dialog-content/fiscalization-business-premise-fina-dialog-content.component';
import {FiscalizationBusinessPremiseFursDialogContentComponent} from '@app/dialogs/fiscalization-business-premise-furs-dialog-content/fiscalization-business-premise-furs-dialog-content.component';
import {FiscalizationElectronicDeviceFursDialogContentComponent} from './fiscalization-electronic-device-furs-form-dialog/fiscalization-electronic-device-furs-dialog-content.component';
import {FiscalizationElectronicDeviceFinaDialogContentComponent} from './fiscalization-electronic-device-fina-form-dialog/fiscalization-electronic-device-fina-dialog-content.component';
import {LeanPayPromoDialogComponent} from './lean-pay-promo-dialog/lean-pay-promo-dialog.component';
import {CreateAssetDialogComponent} from './create-asset-dialog/create-asset-dialog.component';
import {SelectAssetDialogComponent} from './select-asset-dialog/select-asset-dialog.component';
import {AutocompleteAssetComponent} from '@app/partials/partials/autocomplete-asset/autocomplete-asset.component';
import {EndOfTrialNotificationDialogComponent} from '@app/dialogs/end-of-trial-notification-dialog/end-of-trial-notification-dialog.component';
import {ClientConsentDialogComponent} from './client-consent-dialog/client-consent-dialog.component';
import {MarketingExportDialogComponent} from './marketing-export-dialog/marketing-export-dialog.component';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatRadioModule} from '@angular/material/radio';
import {AddBusinessUnitDialogComponent} from '@app/dialogs/add-business-unit-dialog/add-business-unit-dialog.component';
import {EditVehicleBusinessUnitDialogComponent} from './edit-vehicle-business-unit-dialog/edit-vehicle-business-unit-dialog.component';
import {UpgradeProductPackageDialogComponent} from './upgrade-product-package-dialog/upgrade-product-package-dialog.component';
import {DeleteAccountDialogComponent} from '@app/dialogs/delete-account-dialog/delete-account-dialog.component';
import {SubscriptionExpiredDialogComponent} from './subscription-expired-dialog/subscription-expired-dialog.component';
import {PagesModule} from '@app/shared/pages/pages.module';
import {TestPlatesDialogComponent} from '@app/dialogs/test-plates-dialog/test-plates-dialog.component';
import {PpFinancialDialogComponent} from '@app/dialogs/pp-financial-packages-dialog/pp-financial-dialog.component';
import {VideoModalDialogComponent} from './video-modal-dialog/video-modal-dialog.component';
import {ExcelVehicleImportDialogComponent} from '@app/dialogs/excel-vehicle-import-dialog/excel-vehicle-import-dialog.component';
import {MatLegacyTooltipModule} from '@angular/material/legacy-tooltip';
import {NgxFilesizeModule} from 'ngx-filesize';
import {AddUserRoleDialogComponent} from "@app/dialogs/add-user-role-dialog/add-user-role-dialog.component";
import {WarrantyPackagesDialogComponent} from "@app/dialogs/warranty-packages-dialog/warranty-packages-dialog.component";
import {NlbVehicleFormPageComponent} from '@app/dialogs/nlb-vehicle-form-page/nlb-vehicle-form-page.component';
import {BulkVehicleActivateDialogComponent} from '@app/dialogs/bulk-vehicle-activate-dialog/bulk-vehicle-activate-dialog.component';
import {ConfirmUnsubscribeDialogComponent} from './confirm-unsubscribe-dialog/confirm-unsubscribe-dialog.component';
import {MatInputModule} from '@angular/material/input';
import {VehicleBulkAdImportDialogComponent} from "@app/dialogs/vehicle-bulk-ad-import-dialog/vehicle-bulk-ad-import-dialog.component";
import {MatSortModule} from "@angular/material/sort";
import {SuccessPublishAdDialogComponent} from "@app/dialogs/success-publish-ad-dialog/success-publish-ad-dialog.component";
import { AdvertisingSelectDialogComponent } from './advertising-select-dialog/advertising-select-dialog.component';
import { AdvertisingSuccessfulDialogComponent } from './advertising-successful-dialog/advertising-successful-dialog.component';
import { SelectRolesDialogComponent } from './select-roles-dialog/select-roles-dialog.component';
import { ProformaProductPackagePaymentDialogComponent } from './proforma-product-package-payment-dialog/proforma-product-package-payment-dialog.component';
import {ConnectBusinessUnitsExtensionAccountsDialogComponent} from "@app/dialogs/connect-business-units-extension-accounts-dialog/connect-business-units-extension-accounts-dialog.component";
import { AccountLockedDialogComponentComponent } from './account-locked-dialog-component/account-locked-dialog-component.component';
import { OpenProductPackageOfferDialogComponentComponent } from './open-product-package-offer-dialog-component/open-product-package-offer-dialog-component.component';
import {CreateCashExpenditureDialogComponent} from "@app/dialogs/create-cash-expenditure-dialog/create-cash-expenditure-dialog.component";

@NgModule({
  declarations: [
    AddPhotoDialogComponent,
    AddUserDialogComponent,
    MaxUserDialogComponent,
    ChangeEntityCategoryDialogComponent,
    ChangeEntityGroupsDialogComponent,
    ChangeStatusDialogComponent,
    ConfirmDialogComponent,
    EditFieldDialogComponent,
    ExportDocumentDialogComponent,
    FormAlarmDialogComponent,
    FormPrefillDocumentTemplateDialogComponent,
    RepeaterItemFormDialogComponent,
    ShareAdDialogComponent,
    FieldItemFormDialogComponent,
    AddVehicleToCompareComponent,
    AddVehicleToProcessComponent,
    OpenProcessFormComponent,
    SelectClientDialogComponent,
    SelectVehicleDialogComponent,
    SelectClientTypeDialogComponent,
    ChangeClientEntityGroupDialogComponent,
    EntityRelationshipDialogComponent,
    QuickCreateEntityDialogComponent,
    RefreshBrowserDialogComponent,
    GdprDialogComponent,
    DmvCalculatorDialogComponent,
    SelectIbanDialogComponent,
    SelectInvoiceSearchItemDialogComponent,
    SelectInvoiceItemDialogComponent,
    SelectInvoiceTypeVehicleDialogComponent,
    PaymentFormDialogComponent,
    AddReferenceAnyDocumentDialogComponent,
    SelectInvoiceTypeDialogComponent,
    SignatureComponentDialogComponent,
    NlbLeasingInfoCalculatorComponent,
    CompanyTypeClientDialogComponent,
    SwitchToFullModeDialogComponent,
    UpsSwitchToFullModeDialogComponent,
    OnboardingFormDialogComponent,
    OnboardingSuccessDialogComponent,
    SwitchToFullModeSuccessfullDialogComponent,
    CompletedWelcomeOnboardingDialogComponent,
    OnboardingLastQuestionDialogComponent,
    FirstInvoiceCreatedDialogComponent,
    ReadMoreMotiveServiceDialogComponent,
    HowToIssueMotiveServiceDialogComponent,
    ReadMoreAboutWarrantyMotiveServiceDialogComponent,
    SignupFormDialogComponent,
    MotiveServiceChoosePackageDialogComponent,
    FiscalizationBusinessPremiseFormDialogComponent,
    FiscalizationElectronicDeviceFormDialogComponent,
    EditEntityGroupDialogComponent,
    SuccessExportedSpaceInvoicesDocumentDialogComponent,
    NlbLeaseAndGoPromoDialogComponent,
    AdministrationInvalidNumberErrorDialogComponent,
    RequireHelpDialogComponent,
    MotivePackagesDialogComponent,
    TransformEstimateToInvoiceWarningDialogComponent,
    MotiveServiceBuyPackagesDialogComponent,
    MotiveServiceBuyPackagesSuccessDialogComponent,
    SuccessExportedBussinesOverviewComponent,
    ChangeVehicleStatusOverviewTableDialogComponent,
    NlbTypeFormDialogComponent,
    NlbOpenDraftsDialogComponent,
    CreateCashReceiptDialogComponent,
    CreateCashExpenditureDialogComponent,
    FursConfirmDialogComponent,
    FursAutomaticCreditNoteDialogComponent,
    SelectFileUploadFromDialogComponent,
    SelectFilesDialogComponent,
    RestoreVehicleDialogComponent,
    AlreadySoldMoveToArchiveDialogComponent,
    AgreeWithAmpTermsDialogComponent,
    FieldCurrencyFormDialogComponent,
    UserExistsDialogComponent,
    CreateVehicleDialogComponent,
    EmailDialogComponent,
    FiscalizationBusinessPremiseFinaDialogContentComponent,
    FiscalizationBusinessPremiseFursDialogContentComponent,
    FiscalizationElectronicDeviceFursDialogContentComponent,
    FiscalizationElectronicDeviceFinaDialogContentComponent,
    LeanPayPromoDialogComponent,
    CreateAssetDialogComponent,
    SelectAssetDialogComponent,
    AutocompleteAssetComponent,
    EndOfTrialNotificationDialogComponent,
    ClientConsentDialogComponent,
    MarketingExportDialogComponent,
    TestPlatesDialogComponent,
    PpFinancialDialogComponent,
    AddBusinessUnitDialogComponent,
    EditVehicleBusinessUnitDialogComponent,
    UpgradeProductPackageDialogComponent,
    DeleteAccountDialogComponent,
    VideoModalDialogComponent,
    AddUserRoleDialogComponent,
    WarrantyPackagesDialogComponent,
    NlbVehicleFormPageComponent,
    AddUserRoleDialogComponent,
    ExcelVehicleImportDialogComponent,
    BulkVehicleActivateDialogComponent,
    SubscriptionExpiredDialogComponent,
    ConfirmUnsubscribeDialogComponent,
    VehicleBulkAdImportDialogComponent,
    SuccessPublishAdDialogComponent,
    AdvertisingSelectDialogComponent,
    AdvertisingSuccessfulDialogComponent,
    SelectRolesDialogComponent,
    ConnectBusinessUnitsExtensionAccountsDialogComponent,
    ProformaProductPackagePaymentDialogComponent,
    AccountLockedDialogComponentComponent,
    OpenProductPackageOfferDialogComponentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    NgxDropzoneModule,
    MatDatepickerModule,
    ClipboardModule,

    PipesModule,
    PartialsModule,
    BriefdFormModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    CdkTableModule,
    RouterModule,
    QRCodeModule,
    ScrollingModule,
    DirectivesModule,
    MatRadioModule,
    MatSelectModule,
    MatLegacyTooltipModule,
    NgxFilesizeModule,
    PagesModule,
    MatInputModule,
    MatSortModule,
  ],
  exports: [
    AddPhotoDialogComponent,
    AddUserDialogComponent,
    MaxUserDialogComponent,
    ChangeEntityCategoryDialogComponent,
    ChangeEntityGroupsDialogComponent,
    ChangeStatusDialogComponent,
    ConfirmDialogComponent,
    EditFieldDialogComponent,
    ExportDocumentDialogComponent,
    FormAlarmDialogComponent,
    FormPrefillDocumentTemplateDialogComponent,
    RepeaterItemFormDialogComponent,
    ShareAdDialogComponent,
    FieldItemFormDialogComponent,
    SelectClientTypeDialogComponent,
    EntityRelationshipDialogComponent,
    QuickCreateEntityDialogComponent,
    FiscalizationBusinessPremiseFormDialogComponent,
    FiscalizationElectronicDeviceFormDialogComponent,
    PpFinancialDialogComponent,
    NlbVehicleFormPageComponent,
  ],
  entryComponents: [
    AddPhotoDialogComponent,
    AddUserDialogComponent,
    MaxUserDialogComponent,
    ChangeEntityCategoryDialogComponent,
    ChangeEntityGroupsDialogComponent,
    ChangeStatusDialogComponent,
    ConfirmDialogComponent,
    EditFieldDialogComponent,
    ExportDocumentDialogComponent,
    FormAlarmDialogComponent,
    FormPrefillDocumentTemplateDialogComponent,
    RepeaterItemFormDialogComponent,
    ShareAdDialogComponent,
    FieldItemFormDialogComponent,
    SelectClientTypeDialogComponent,
    EntityRelationshipDialogComponent,
    QuickCreateEntityDialogComponent,
    PpFinancialDialogComponent
  ],
})
export class DialogsModule {
}
