export enum AccountFeaturesEnum {
  // Deprecated
  HAS_ERACUN = 'hasEracun',
  FEATURE_FLAG_CREATE_VEHICLE_BY_VIN = 'createVehicleByVIN',

  FEATURE_FLAG_ROLES_AND_PERMISSIONS = 'rolesAndPermissions',
  FEATURE_FLAG_ADMINISTRATION_V2 = 'administrationV2',
  FEATURE_FLAG_ANALYTICS = 'analytics',
  FEATURE_FLAG_DMV_CALCULATION = 'dmvCalculation',
  FEATURE_FLAG_FINANCING = 'financing',
  FEATURE_FLAG_FINANCING_V3 = 'financingV3',
  FEATURE_FLAG_FISCALIZATION_V2 = 'fiscalizationV2',
  FEATURE_FLAG_MARKET_STUDY = 'marketStudy',
  FEATURE_FLAG_NLB_LEASING_PRE_UX = 'nlbLeasingPreUX',
  FEATURE_FLAG_SF_PRODUCTS = 'sfProducts',
  FEATURE_FLAG_EDIT_FINAL_SALE_CALCULATION = 'editFinalSaleCalculation',
  FEATURE_FLAG_SPACE_INVOICES_TEMPLATES = 'spaceInvoicesTemplates',
  FEATURE_FLAG_SALE_AND_LEASEBACK = 'saleAndLeaseBack',
  ASSETS = 'assets',
  NLBUX = 'nlbUX',
  LEANPAY = 'leanpay',
  FEATURE_FLAG_WARRANTIES_V2 = 'warrantiesV2',
  WARRANTIES_V1 = 'warrantiesV1',

  MARKET_RESEARCH_CONSENTS = 'marketResearchConsents',
  MOBILE_ACCESS = 'mobileAccess',
  ACCOUNTANT_ACCESS = 'accountantAccess',
  ROLES_AND_PERMISSIONS = 'rolesAndPermissions',
  IMPLEMENTATION_AND_SUPPORT_BY_PHONE = 'implementationAndSupportByPhone',

  DOCUMENTS = 'documents',
  DOCUMENTS_DOCX = 'documentsDOCX',
  FEATURE_FLAG_DEMO_FINANCING = 'demoFinancing',

  FEATURE_FLAG_BUSINESS_UNITS = 'businessUnits',
  HAS_ADDIKO_INTEGRATION= 'hasAddikoIntegration',

  IS_AVAILABLE_DOBERAVTO = 'isAvailableDoberAvto',
  IS_AVAILABLE_SHOPAJ = 'isAvailableShopaj',
  IS_AVAILABLE_VEHICLE_IMPORT = 'isAvailableVehicleImport',
  IS_AVAILABLE_NJUSKALO = 'isAvailableNjuskalo',
  IS_AVAILABLE_MOTIVE_SERVICE = 'isAvailableMotiveService',
  PRINT_VEHICLE_CARD = 'printVehicleCard',
  IS_AVAILABLE_EXCEL_VEHICLE_IMPORT = 'isAvailableExcelVehicleImport',
  IS_AVAILABLE_INDEX_HR = 'isAvailableIndexHr',

  CALCULATIONS_AND_COSTS_MANAGEMENT = 'calculationsAndCostsManagement',
  DOCUMENT_ARCHIVE = 'documentArchive',
  ADVERTISEMENT_VIRTUAL_SALON = 'advertisementVirtualSalon',
  ADVERTISEMENT_SOCIAL_NETWORKS = 'advertisementSocialNetworks',
  IS_AVAILABLE_PHYRON = 'isAvailablePhyron',
  CLIENT_REMINDERS = 'clientReminders',
  CLIENT_ACTIVITY_TRACKING = 'clientActivityTracking',
  CLIENT_VAT_SEARCH = 'clientVatSearch',
  ADMINISTRATION_EXPORT = 'administrationExport',
  LIMITED_ADMINISTRATION = 'limitedAdministration',
  DISABLE_PACKAGE_CHANGE = 'disablePackageChange',
  IS_AVAILABLE_EXTENSION_VEHICLE_IMPORT = 'isAvailableExtensionVehicleImport',

  HAS_AI_SUGGESTIONS = 'hasAISuggestions',
  BETA_ADMINISTRATION_EXPORTS = 'betaAdministrationExports',
  IS_AVAILABLE_TERMS_AND_CONDITIONS = 'isAvailableTermsAndConditions',
  HAS_AUTOMATIC_VEHICLE_ARCHIVING = 'hasAutomaticVehicleArchiving',
  HAS_VIRTUAL_SALON_V2 = 'hasVirtualSalonV2',

}
