import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '@src/environments/environment';
import {isPlatformBrowser} from '@angular/common';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Auth, SingleResult} from '../interfaces';
import {AuthRegisterCredentials} from '@app/services/authRegisterCredentials';
import {AuthCredentials} from '@app/services/authCredentials';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static AUTH_KEY = 'auth';
  private static SUPER_ADMIN_TAKE_CONTROL_ACCOUNT_UUID = 'control_account_uuid';

  private authChangesSubject = new BehaviorSubject<Auth | null>(null);
  private authControlAccountChangeSubject = new BehaviorSubject<{ name: string; accountUuid: string } | null>(null);
  private invalidateConfigChanges = new BehaviorSubject<any>(null);

  private authControlAccountChangeChannel = new BroadcastChannel('authControlChangeChannel');
  private invalidateConfigChannel = new BroadcastChannel('invalidateConfigChannel');

  private tabId = Math.random().toString(36);

  constructor(private http: HttpClient,
              @Inject(PLATFORM_ID) private platformId: any) {


    this.authChangesSubject.next(this.getAuth());

    this.setupCrossTabsSync();

    this.crossTabsInvalidateConfig();

    this.onPageLoadAuthControlInit();
  }

  getAuthChanges(): Observable<Auth | null> {
    return this.authChangesSubject;
  }

  getAccountControlChanges() {
    return this.authControlAccountChangeSubject;
  }

  getInvalidateConfigChanges() {
    return this.invalidateConfigChanges;
  }

  login(credentials: AuthCredentials) {
    return this.http.post(`${environment.apiUrl}/api/users/login`, credentials)
      .pipe(tap((result: SingleResult<Auth>) => this.storeAuth(result.data)));
  }

  /**
   * @Deprecated use registerDemo instead
   * @param credentials
   */
  register(credentials: AuthCredentials) {
    return this.http.post(`${environment.apiUrl}/api/users/register`, credentials)
      .pipe(tap((result: SingleResult<Auth>) => this.storeAuth(result.data)));
  }

  useCode(demoCode: string) {
    return this.http.post(`${environment.apiUrl}/api/demo/exchange-code`, {demoCode})
      .pipe(tap((result: SingleResult<Auth>) => this.storeAuth(result.data)));
  }

  registerDemo(credentials: AuthRegisterCredentials) {
    return this.http.post(`${environment.apiUrl}/api/demo/register`, credentials)
      .pipe(tap((result: SingleResult<Auth>) => this.storeAuth(result.data)));
  }

  sendResetPasswordLink(credentials: { email: string }) {
    return this.http.post(`${environment.apiUrl}/api/password/send-reset-link`, credentials);
  }

  resetPassword(credentials: { email: string; token: string; password: string }) {
    return this.http.post(`${environment.apiUrl}/api/password/reset-password`, credentials);
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.clear();
    }
    this.authChangesSubject.next(null);
    return this.http.post(`${environment.apiUrl}/api/users/logout`, {});
  }

  seedDemo(type: number) {
    return this.http.post(`${environment.apiUrl}/api/demo`, {type});
  }

  isLoggedIn(): boolean {
    return this.getAuth() !== null;
  }

  getToken() {
    if (this.isLoggedIn()) {
      return this.getAuth()?.token || null;
    }

    return null;
  }

  getAuth(): Auth | null {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem(AuthService.AUTH_KEY));
    }
    return null;
  }

  getAccountControl(): { name: string; accountUuid: string } | null {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem(AuthService.SUPER_ADMIN_TAKE_CONTROL_ACCOUNT_UUID));
    }

    return null;
  }

  public setupCookieForEasierImageLoading() {
    return this.http.get(`${environment.apiUrl}/api/users/me/cookie`, {withCredentials: true});
  }

  private storeAuth(auth: Auth) {
    if (!isPlatformBrowser(this.platformId) || auth.isDeactivated) {
      return;
    }

    localStorage.setItem(AuthService.AUTH_KEY, JSON.stringify(auth));
    //  Set cookie for images
    this.setupCookieForEasierImageLoading().subscribe(() => {
      this.authChangesSubject.next(auth);
    });
  }

  isAdmin() {
    if (this.isLoggedIn()) {
      return !!this.getAuth()?.isAdmin;
    }
    return false;
  }

  takeControl(control: { name: string; accountUuid: string } | null) {
    if (isPlatformBrowser(this.platformId)) {

      if (control) {
        localStorage.setItem(AuthService.SUPER_ADMIN_TAKE_CONTROL_ACCOUNT_UUID, JSON.stringify(control));
      } else {
        localStorage.removeItem(AuthService.SUPER_ADMIN_TAKE_CONTROL_ACCOUNT_UUID);
      }

      window.location.reload();

      this.authControlAccountChangeSubject.next(control);

      // Notify other tabs
      this.authControlAccountChangeChannel.postMessage({
        control,
        tabId: this.tabId
      });
    }
  }

  invalidateConfigOnAllTabs(eventKey: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.invalidateConfigChannel.postMessage({
        eventKey,
        tabId: this.tabId
      });
    }
  }

  private crossTabsInvalidateConfig() {
    if (isPlatformBrowser(this.platformId)) {
      this.invalidateConfigChannel.onmessage = (ev) => {
        // Prevent loops
        if (ev.data.tabId && ev.data.tabId === this.tabId) {
          return;
        }

        this.getInvalidateConfigChanges();
        window.location.reload();
      }
    }
  }

  private setupCrossTabsSync() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.authControlAccountChangeChannel.onmessage = (ev) => {
      // Prevent loops
      if (ev.data.tabId && ev.data.tabId === this.tabId) {
        return;
      }

      console.warn('Received control change', ev.data);

      // if (confirm(`Received change of Super Admin control, accept it?`)) {
      //   this.authControlAccountChangeSubject.next(ev.data.control);
      //   window.location.reload();
      // }
    };
  }

  private onPageLoadAuthControlInit() {
    const accountControl = this.getAccountControl();
    if (accountControl) {
      if (confirm(`You are currently controlling an account: \n >> ${accountControl.name}.\n Do you want to CONTINUE?`)) {
        this.authControlAccountChangeSubject.next(this.getAccountControl());
      } else {
        this.takeControl(null);
      }
    }
  }
}
