import {ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {SectionItem} from '../../../interfaces/form/section-item';
import {FormGroup, Validators} from '@angular/forms';
import {LayoutItem} from '../../../interfaces/form/layout-item';
import {FieldItem} from '../../../interfaces/form/field-item';
import {Form} from '../../../interfaces/entities/form';
import {Field} from '../../../interfaces';
import {takeUntil} from 'rxjs/operators';
import {FieldHelperService} from '@app/services/util/field-helper.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-form-layout',
  templateUrl: './form-layout.component.html',
  styleUrls: ['./form-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLayoutComponent implements OnInit, OnDestroy {

  @ViewChildren('inputElement') inputElements: QueryList<ElementRef>;
  @Input() form: Form;

  @Input() sectionItem: SectionItem;

  @Input() formGroup: FormGroup;

  @Input('hidden') isHidden = false;

  @Input() hideHeader = true;

  protected destroy$ = new Subject<void>();

  constructor(private fieldHelper: FieldHelperService) {
  }

  ngOnInit(): void {
    this.setRequiredFields();
  }

  setRequiredFields() {
    const fields = this.form.fields;
    const keys = Object.keys(fields);
    for (const key of keys) {
      const config = fields[key].config;
      if (config.makesRequired) {
        const fieldsControls = this.formGroup.controls;

        for (const fieldControlKey in fieldsControls) {
          (fieldsControls[fieldControlKey] as FormGroup).controls[key]?.valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((valueOfField) => {
              for (const requiredFields of config.makesRequired) {
                let requiredField = null;
                for (const fieldKey in fields) {
                  if (requiredFields === fields[fieldKey].config.typeId) {
                    requiredField = fields[fieldKey].fieldUuid;
                  }
                }

                if (valueOfField === 'electric') {
                  (fieldsControls[fieldControlKey] as FormGroup).controls[requiredField]?.setValidators([Validators.required]);
                } else {
                  (fieldsControls[fieldControlKey] as FormGroup).controls[requiredField]?.removeValidators([Validators.required]);
                }
                (fieldsControls[fieldControlKey] as FormGroup).controls[requiredField]?.updateValueAndValidity({emitEvent: true});
                (fieldsControls[fieldControlKey] as FormGroup).controls[requiredField]?.markAsTouched();
              }
            });
        }
      }
    }
  }

  asSectionItem(item: LayoutItem) {
    return item as SectionItem;
  }

  asFieldItem(item: LayoutItem) {
    return item as FieldItem;
  }

  keys(controls: any) {
    return Object.keys(controls);
  }

  fieldsUpdated(event: Array<{ fieldUuid: string, value: number }> | Field<any>) {
    if (Array.isArray(event)) {
      event.forEach((field) => {
        this.formGroup.controls[field.fieldUuid]?.setValue(field.value);
      });
    } else {
      this.formGroup.controls[event.fieldUuid].setValue(event.value);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  focusNextField(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      const target = event.target as HTMLElement;

      // If the target is the rich text editor, don't focus the next field
      if (target.classList.contains('ql-editor')) {
        return;
      }

      const inputs = document.querySelectorAll('[tabindex=\'0\']');
      const currentIndex = Array.from(inputs).findIndex((input) => input === event.target);

      if (currentIndex < inputs.length - 1) {
        const nextInput = inputs[currentIndex + 1] as HTMLElement;
        nextInput.focus();

        if (nextInput.tagName === 'BUTTON') {
          setTimeout(() => {
            const inputs = document.querySelectorAll('[tabindex=\'0\']');

            const nextInput = inputs[currentIndex + 1] as HTMLElement;
            nextInput.focus();
          }, 50);
        }
      }
    }
  }
}
