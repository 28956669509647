import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '@src/environments/environment';
import {Observable} from 'rxjs';
import {ArrayResult} from '@app/interfaces/responses/array-result';
import {ApiCachingService} from './api-caching.service';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ExportApiService {

  public readonly saleDocuments = ['briefd.document.workflows.vehicle_authorization', 'briefd.document.workflows.vehicle_warranty', 'briefd.document.workflows.vehicle_handover_note', 'briefd.document.workflows.deposit', 'briefd.document.workflows.vehicle_authorization_for_company', 'briefd.document.workflows.vehicle_warranty_for_company', 'briefd.document.workflows.vehicle_handover_note_for_company'];

  constructor(private httpClient: HttpClient, private cachingApi: ApiCachingService) {
  }

  exportEntityExcel(type: string, entityUuid: string) {
    return this.httpClient.post(`${environment.apiUrl}/api/exports/excel`, {type, entityUuid});
  }

  exportEntityPDF(type: string, entityUuid: string) {
    return this.httpClient.post(`${environment.apiUrl}/api/exports/pdf`, {type, entityUuid});
  }

  getDocuments(showHidden: boolean = false): Observable<ArrayResult<any>> {
    let params = new HttpParams();
    let cacheKey = 'documents';
    if (showHidden) {
      params = params.append('showHidden', 'true');
      cacheKey += 'Hidden';
    }

    return this.cachingApi.getCached(cacheKey, this.httpClient.get<ArrayResult<any>>(`${environment.apiUrl}/api/exports/documents`, {params}))
      .pipe(
        map(result => {
          // Sort documents by name
          result.data = result.data.sort((a, b) => a.name.localeCompare(b.name));
          return result;
        }));
  }

  processDocument(entityUuid: string, prefillDocumentTemplateUuid: string, workflowResultUuid: string | null) {
    return this.httpClient.post(`${environment.apiUrl}/api/exports/processDocument`, {
      workflowResultUuid,
      prefillDocumentTemplateUuid,
      entityUuid
    }, {responseType: 'blob'});
  }

  storeDocument(body: any, file) {
    const formData = new FormData();
    formData.append('file', file, file.name);

    Object.keys(body).forEach(key => formData.append(key, body[key]));

    return this.httpClient.post(`${environment.apiUrl}/api/super-admin/exports/documents`, formData);
  }

  deleteDocument(uuid: string) {
    return this.httpClient.delete(`${environment.apiUrl}/api/super-admin/exports/documents/${uuid}`);
  }

  updateDocument(uuid: any, body: any, file: any) {
    const formData = new FormData();
    if (file) {
      formData.append('file', file, file.name);
    }

    Object.keys(body).forEach(key => formData.append(key, body[key]));
    formData.append('_method', 'PUT');

    return this.httpClient.post(`${environment.apiUrl}/api/super-admin/exports/documents/${uuid}`, formData);
  }

  downloadUploads(uploadUuids: string[]) {
    return this.httpClient.post(`${environment.apiUrl}/api/exports/uploads`, {
      uploadUuids
    }, {responseType: 'blob'});
  }


  downloadExportVehicleData(vehicleUuids: string[]) {
    return this.httpClient.post(`${environment.apiUrl}/api/exports/vehicleOverviewData`, {vehicleUuids}, {responseType: 'blob'});
  }

}
