export enum PermissionEnum {
  DASHBOARD_DASHBOARD_VIEW = 'dashboard.dashboard.view',
  DASHBOARD_ANALYTICS_VIEW = 'dashboard.analytics.view',
  GLOBAL_SEARCH_VIEW = 'global-search.view',

  MENU_GLOBAL_DASHBOARD_VIEW = 'menu.global.dashboard.view',
  MENU_GLOBAL_VEHICLES_VIEW = 'menu.global.vehicles.view',
  MENU_GLOBAL_CLIENTS_VIEW = 'menu.global.clients.view',
  MENU_GLOBAL_DOCUMENTS_VIEW = 'menu.global.documents.view',
  MENU_GLOBAL_ASSETS_VIEW = 'menu.global.assets.view',
  MENU_GLOBAL_ADMINISTRATION_VIEW = 'menu.global.administration.view',
  MENU_GLOBAL_WARRANTIES_VIEW = 'menu.global.warranties.view',
  MENU_GLOBAL_FINANCING_VIEW = 'menu.global.financing.view',
  MENU_GLOBAL_BUSINESS_OVERVIEW_VIEW = 'menu.global.business-overview.view',
  MENU_GLOBAL_AUCTIONS_VIEW = 'menu.global.auctions.view',
  MENU_GLOBAL_MARKET_STUDY_VIEW = 'menu.global.market-study.view',
  MENU_TOOLS_VIEW = 'menu.tools.view',

  MENU_ADMINISTRATION_DASHBOARD_VIEW = 'menu.administration.dashboard.view',
  MENU_ADMINISTRATION_DOCUMENTS_VIEW = 'menu.administration.documents.view',
  MENU_ADMINISTRATION_INVOICES_VIEW = 'menu.administration.invoices.view',
  MENU_ADMINISTRATION_PROFORMAS_VIEW = 'menu.administration.proformas.view',
  MENU_ADMINISTRATION_ADVANCES_VIEW = 'menu.administration.advances.view',
  MENU_ADMINISTRATION_CREDIT_NOTES_VIEW = 'menu.administration.credit-notes.view',
  MENU_ADMINISTRATION_PAYMENTS_VIEW = 'menu.administration.payments.view',
  MENU_ADMINISTRATION_CASH_RECEIPTS_VIEW = 'menu.administration.cash-receipts.view',
  MENU_ADMINISTRATION_CASH_EXPENDITURES_VIEW  = 'menu.administration.cash-expenditures.view',
  MENU_ADMINISTRATION_EXPORTS_VIEW = 'menu.administration.exports.view',

  MENU_BUSINESS_OVERVIEW_TABLE_VIEW = 'menu.business-overview.table.view',
  MENU_BUSINESS_OVERVIEW_GRAPHS_VIEW = 'menu.business-overview.graphs.view',

  TAB_DOCUMENTS_TEMPLATES_VIEW = 'tab.documents.templates.view',
  TAB_DOCUMENTS_ISSUED_VIEW = 'tab.documents.issued.view',
  TAB_DOCUMENTS_ARCHIVED_VIEW = 'tab.documents.archived.view',

  TAB_VEHICLES_VEHICLES_VIEW = 'menu.vehicles.vehicles.view',
  TAB_VEHICLES_ADS_VIEW = 'menu.vehicles.ads.view',
  TAB_VEHICLES_ARCHIVE_VIEW = 'menu.vehicles.archive.view',
  TAB_VEHICLES_IMPORTS_VIEW = 'menu.vehicles.imports.view',

  SUBMENU_VEHICLES_DATA_VIEW = 'submenu.vehicles.data.view',
  SUBMENU_VEHICLES_BUYING_VIEW = 'submenu.vehicles.buying.view',
  SUBMENU_VEHICLES_INSPECTION_VIEW = 'submenu.vehicles.inspection.view',
  SUBMENU_VEHICLES_CMR_VIEW = 'submenu.vehicles.cmr.view',
  SUBMENU_VEHICLES_COC_VIEW = 'submenu.vehicles.coc.view',
  SUBMENU_VEHICLES_ADS_DIGITAL_VIEW = 'submenu.vehicles.ads-digital.view',
  SUBMENU_VEHICLES_ADS_PRINT_VIEW = 'submenu.vehicles.ads-print.view',
  SUBMENU_VEHICLES_SELL_DOCUMENTS_VIEW = 'submenu.vehicles.sell-documents.view',
  SUBMENU_VEHICLES_WARRANTIES_VIEW = 'submenu.vehicles.warranties.view',
  SUBMENU_VEHICLES_FINANCING_VIEW = 'submenu.vehicles.financing.view',
  SUBMENU_VEHICLES_ISSUE_INVOICE_VIEW = 'submenu.vehicles.issue-invoice.view',
  SUBMENU_VEHICLES_CALC_VIEW = 'submenu.vehicles.calc.view',
  SUBMENU_VEHICLES_CONTACTS_VIEW = 'submenu.vehicles.contacts.view',
  SUBMENU_VEHICLES_ARCHIVE_DOCUMENTS_VIEW = 'submenu.vehicles.archive-documents.view',
  SUBMENU_VEHICLES_ARCHIVE_FILES_VIEW = 'submenu.vehicles.archive-files.view',
  SUBMENU_VEHICLES_ARCHIVE_INVOICES_VIEW = 'submenu.vehicles.archive-invoices.view',
}
