<ng-container *ngIf="control.errors as errors">
  <div class="flex flex-col gap-1 input-error-message">
    <div class="flex-1" *ngIf="errors.customError">
      <span i18n="Form validation error">Napaka:</span>&nbsp;{{ control.errors.customError }}.
    </div>

    <div class="flex-1" *ngIf="errors.min">
      <span i18n="Form validation error">Mora biti večje od</span>&nbsp;{{ control.errors.min.min }}.
    </div>

    <div class="flex-1" *ngIf="errors.minlength">
      <span i18n="Form validation error">Mora biti daljše/več kot</span>&nbsp;{{ control.errors.minlength.requiredLength }}.
    </div>

    <div class="flex-1" *ngIf="errors.max">
      <span i18n="Form validation error">Mora biti manjše od</span>&nbsp;{{ control.errors.max.max }}.
    </div>

    <div class="flex-1" *ngIf="errors.maxPercent">
      <span i18n="Form validation error">Mora biti manjše od</span>&nbsp;{{ control.errors.maxPercent.max }}
      <span> %.</span>
    </div>

    <div class="flex-1" *ngIf="errors.maxlength">
      <span i18n="Form validation error">Mora biti krajše/manj kot</span>&nbsp;{{ control.errors.maxlength.requiredLength }}.
    </div>

    <div class="flex-1" *ngIf="errors.required">
      <span i18n="Form validation error">Polje je obvezno.</span>
    </div>

    <div class="flex-1" *ngIf="errors.pattern">
      <span i18n="Form validation error">Napačna oblika!</span>
    </div>

    <div class="flex-1" *ngIf="errors.prefixError">
      <span i18n="Form validation error">Se mora začeti z: {{ errors.prefixError.requiredPrefix }}</span>
    </div>

    <div class="flex-1" *ngIf="errors.upnReferenceError">
      <span i18n="Form validation error">Sklic ima napačno obliko ali se ne začne z: {{ errors.upnReferenceError.requiredPrefixes.join(', ') }}</span>
    </div>
  </div>
</ng-container>
