<div class="card bg-white relative border-none">
  <div class="card-header p-3 sm:p-5">
    <div class="flex items-center justify-between">
      <p class="flex mb-0 pb-0 text-greenDarkOlive text-lg sm:text-xl font-bold"><span i18n>Uredi preizkusne tablice</span></p>

      <button (click)="close()" class="btn border-transparent shadow-none text-gray hover:text-grayDark fadeInFadeOut"><i
        class="fas fa-times text-lg"></i></button>
    </div>
  </div>
  <hr class="mb-0 mt-0">
  <div class="card-content overflow-auto" style="max-height: 70vh">
    <div *ngIf="isLoading;  else showContent">
      <app-form-shimmer></app-form-shimmer>
    </div>

    <ng-template #showContent>
      <div class="text-right">
        <button class="btn btn-primary" [disabled]="isSaving" (click)="addOption()"><i
                class="fas fa-plus"
                style="padding-bottom: 0.05rem"></i>
          <span i18n class="ml-2">Dodaj tablico</span>
        </button>
      </div>

      <ng-template #showNoContacts>
        <table class="mt-8">
          <tr>
            <th class="py-3 px-4"><span i18n="">Številka preizkusne tablice</span></th>
            <th class="py-3 px-4"><span i18n="">številka dovoljenja UE</span></th>
            <th class="py-3 px-4"></th>
          </tr>
        </table>
        <div class="bg-white border border-grayTableBorder border-t-0 flex justify-center items-center" style="height:8rem;">

          <h2><span class="text-base" i18n="">Niste dodali še nobene preizkusne tablice</span></h2>
        </div>

      </ng-template>

      <table class="mt-8 mb-4" *ngIf="options.length; else showNoContacts">
        <tr>
          <th class="py-3 px-4"><span i18n="">Številka preizkusne tablice</span></th>
          <th class="py-3 px-4"><span i18n="">številka dovoljenja UE</span></th>
          <th class="py-3 px-4"></th>
        </tr>
        <tr *ngFor="let option of options" class="bg-white">

          <td class="py-6 px-4">
            <label class="block">
              <input class="m-0" type="text" autocomplete="off"
                     [(ngModel)]="option.value.value"
                     [ngModelOptions]="{standalone: true}"
                     [disabled]="isSaving"
                     [ngClass]="{'input-error': !option.value.value }">
            </label>
          </td>
          <td class="py-6 px-4 relative">
            <label class="block">
              <input class="m-0" type="text" autocomplete="off"
                     [(ngModel)]="option.value.license"
                     [ngModelOptions]="{standalone: true}"
                     [disabled]="isSaving"
                     [ngClass]="{'input-error': !option.value.license }">
            </label>
          </td>
          <td style="width: 10px" class="py-6 px-4 text-center">
            <button class="p-3 cursor-pointer hover:text-redHover" [disabled]="isSaving" (click)="removeOption(option)">
              <i class="fas fa-times text-red"></i>
            </button>
          </td>
        </tr>
      </table>

      <hr class="mt-0">

      <div class="flex justify-end mt-4">
        <button class="btn btn-primary" (click)="save()" [disabled]="!(options | globalPipeFunction : areOptionsValid)  || isSaving"><i
                class="fas fa-save mr-2"></i><span i18n>Shrani</span>
        </button>
        <button class="btn btn-lg border-grayLight text-grayDark ml-4" (click)="close()" i18n>Zapri</button>
      </div>
    </ng-template>
  </div>
</div>
