<div class="flex flex-col">
  <div *ngFor="let item of files; let i = index; let last = last"
       [ngClass]="{'py-1': i > 0, 'pb-1': i === 0, 'mb-3': last}">
    <ng-container *ngIf="item.upload; else showLoadingFile">
      <div class="card bg-white flex flex-row w-full justify-center items-center text-sm pl-2 py-1">
        <div style="flex-basis: 20px; shrink: 0"><i class="fas fa-file mr-2 text-primary"></i></div>
        <div style="grow: 1; white-space: nowrap; text-overflow: ellipsis; overflow: hidden" class="pr-2">
          {{ item.upload.data.files[0].fileName }}
        </div>
        <div style="flex-basis: 120px; shrink: 0">
          <span class="text-grayDark mr-2">{{(item.upload.data.files[0].size / 1000000).toFixed(2) | number }} Mb</span>

          <button (click)="removeFile(item)" type="button"
                  class="btn btn-sm btn-grayDark-link">
            <i class="fas fa-trash-alt"></i></button>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #showLoadingFile>
    <div class="card bg-white flex flex-row w-full text-sm pl-2 py-2 mb-3">
      <span i18n="Loading text for file upload" class="text-grayDark text-sm py-1">Datoteka se nalaga...</span>
    </div>
  </ng-template>
</div>

<div class="mb-2">
  <div ngx-dropzone (change)="onSelect($event)" [multiple]="true"
       [attr.data-field]="field?.fieldUuid"
       [attr.data-typeId]="field?.config?.typeId"
       [maxFileSize]="(field?.config?.maxFileSize || 20480) * 1024"
       class="dropzone bg-grayHover"
       style="background: #F3F3F3 !important; color: black !important; border-color: #DDDDDD !important; height: 8rem !important;">
    <ngx-dropzone-label class="flex flex-col items-center">
      <div><img src="assets/images/upload.png"></div>
      <div><p class="text-grayDark text-sm mt-3" i18n="Upload hint"><span
              class="text-primary font-bold hover:underline">Dodaj datoteko</span> ali odloži datoteko sem</p></div>
    </ngx-dropzone-label>
  </div>
</div>

<div>
  <p class="text-xs text-grayDark">
    <span i18n="file input">Največja dovoljena velikost datoteke je</span> {{ (field?.config?.maxFileSize || 20480) / 1024 | number:'1.0-0' }}
    MB</p>
</div>
