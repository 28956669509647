import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Field} from '@app/interfaces';
import {Subscription} from 'rxjs';
import {FormDataService} from '@app/services/data/form-data.service';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {

  @Input() field: Field<any>;
  @Input() hideLabel = false;

  @Input() formGroup: FormGroup;

  private subscription: Subscription = new Subscription();

  @HostBinding('class.w-1/2') @Input() half = true;
  isHidden: any;

  constructor(
    private formDataService: FormDataService
  ) {
  }

  ngOnInit(): void {
    if (this.formGroup) {
      // @ts-ignore
      this.isHidden = this.formGroup.controls[this.field.fieldUuid].hidden;
      this.formGroup.controls[this.field.fieldUuid].valueChanges.subscribe((x) => {
        // @ts-ignore
        this.isHidden = this.formGroup.controls[this.field.fieldUuid].hidden;
      });
    }

    this.subscription.add(this.formDataService.fieldValueUpdates().subscribe((updateValues) => {
      if (updateValues !== null && updateValues !== undefined && updateValues[this.field.config.typeId] !== undefined && this.field.type !== 'repeater') {
        this.formGroup.controls[this.field.fieldUuid].setValue(updateValues[this.field.config.typeId]);
      }
    }));
  }

  keys(controls: {}) {
    return Object.keys(controls);
  }
}
