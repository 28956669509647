import {ChangeDetectorRef, Component, Input, NgModule, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Field} from '@app/interfaces';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {UserApiService} from "@app/services/api/user-api.service";
import {TaxNumberEnum} from "@app/enums/TaxNumberEnum";

@Component({
    selector: 'app-form-input-tax-number',
    templateUrl: './form-input-tax-number.component.html',
    styleUrls: ['./form-input-tax-number.component.scss']
})
export class FormInputTaxNumberComponent implements OnInit, OnDestroy {
    @Input() control: FormControl;

    @Input() formGroup: FormGroup;

    @Input() field: Field<string>;

    private destroy$ = new Subject<void>();

    mask = [/[A-Z]/, /[A-Z]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    addMask = false;
    prefix = '';
    taxSubject = TaxNumberEnum.IS_TAX_PAYER;

  constructor(private cdr: ChangeDetectorRef, private userService: UserApiService) {}

    ngOnInit() {
        this.userService.getUserCountryCode().subscribe((countryCode) => {
            this.prefix = countryCode.data.toUpperCase();

            this.setMask();
        });

        this.control.statusChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.cdr.detectChanges();
           });

        this.taxSubject = this.formGroup.controls[TaxNumberEnum.IS_TAX_PAYER] ? TaxNumberEnum.IS_TAX_PAYER : TaxNumberEnum.TAX_SUBJECT;

        this.formGroup.controls[this.taxSubject].valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((isTaxPayer: boolean) => {
                this.addMask = isTaxPayer;
                this.setPrefixIfNeeded(isTaxPayer);
            });

           this.formGroup.controls[TaxNumberEnum.TAX_NUMBER]?.valueChanges
             .pipe(takeUntil(this.destroy$))
             .subscribe((newValue: string) => {
               if (this.addMask && !newValue.startsWith(this.prefix)) {
                 this.control.setValue(this.prefix);
               }
             });
    }

    ngOnDestroy() {
        this.destroy$.next();
    }

    setMask() {
        this.addMask = !!this.formGroup.controls[this.taxSubject]?.value;
        this.setPrefixIfNeeded(this.addMask);
    }

    setPrefixIfNeeded(isMask) {
        if (isMask) {
            const currentValue = this.control.value || '';
            if (!currentValue.startsWith(this.prefix)) {
                this.control.setValue(this.prefix + currentValue);
            }
        } else if (this.control.value?.startsWith(this.prefix)) {
            this.control.setValue(this.control.value.substring(this.prefix.length));
        }
    }
}
