import {ChangeDetectorRef, Component, ElementRef, Input} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Field} from '@app/interfaces';
import {firstValueFrom, Subject} from 'rxjs';
import {map, take, takeUntil} from 'rxjs/operators';
import Quill from "quill";
import {GenerateTextModule} from "@app/shared/briefd-form/field-types/form-input-rich-text/quill-generate-text.module";
import {QuillModules} from "ngx-quill/config";
import {ActivatedRoute} from "@angular/router";
import {SuggestionsApiService} from "@app/services/api/suggestions-api.service";
import {ConfigAPIService} from "@app/services/api/config-api.service";
import {AccountFeaturesEnum} from "@app/interfaces/config/accountFeaturesEnum";

const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

@Component({
  selector: 'app-form-input-rich-text',
  templateUrl: './form-input-rich-text.component.html',
  styleUrls: ['./form-input-rich-text.component.scss']
})
export class FormInputRichTextComponent {

  @Input() control: AbstractControl;
  @Input() field: Field<string>;

  private destroy$ = new Subject<void>();
  public quillConfiguration: QuillModules = {
    clipboard: {
      matchVisual: false
    },
    tabIndex: 0,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons

      [{'list': 'ordered'}, {'list': 'bullet'}],
      [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent

      [{'header': [1, 2, 3, 4, 5, 6, false]}],

      [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      [{'align': []}],

      ['clean'],                                         // remove formatting button

      ['link', 'image']                         // link and image, video
    ],
  };


  constructor(private cdr: ChangeDetectorRef,
              private el: ElementRef,
              private activatedRoute: ActivatedRoute,
              private configApiService: ConfigAPIService,
              private suggestionService: SuggestionsApiService) {
  }

  ngOnInit() {
    const element = this.el.nativeElement.querySelector('.ql-editor');
    if (element) {
      element.setAttribute('tabindex', '0');
    }
    this.control.statusChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    if (this.field.config.quillConfiguration) {
      this.quillConfiguration = this.field.config.quillConfiguration;
    }

    this.configApiService.getAccountConfig().pipe(takeUntil(this.destroy$), take(1))
      .subscribe((accountConfig) => {
        console.log('initiateGenerateTextModule', this.activatedRoute.snapshot.params, accountConfig);

        if (accountConfig.accountFeatureFlags[AccountFeaturesEnum.HAS_AI_SUGGESTIONS]) {
          this.initiateGenerateTextModule();
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private initiateGenerateTextModule() {
    const params = this.activatedRoute.snapshot.params;
    let vehicleUuid = params.vehicleUuid;
    if (!vehicleUuid && !params.entityUuid && params.entityType === 'vehicle') {
      vehicleUuid = params.entityUuid;
    }

    if (!vehicleUuid) {
      return;
    }

    Quill.register('modules/generateTextModule', GenerateTextModule, true);

    this.quillConfiguration.generateTextModule = {
      fetchContent: (option, content) => {
        if (option === 'vehicleAdDescription') {
          return firstValueFrom(
            this.suggestionService.vehicleAdDescription(vehicleUuid, content).pipe(
              takeUntil(this.destroy$),
              map((response: any) => response.content))
          )
        }

        return firstValueFrom(
          this.suggestionService.vehicleGeneralDescription(vehicleUuid, content).pipe(
            takeUntil(this.destroy$),
            map((response: any) => response.content))
        )

      },
      buttons: {
        'vehicleAdDescription': $localize`:@@form.input.rich-text.ai-vehicle-ad-description:Vehicle Ad description`,
        'vehicleGeneralDescription': $localize`:@@form.input.rich-text.ai-vehicle-general-description:Vehicle General description`,
      }
    };
  }
}
